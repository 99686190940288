<template>
  <!-- <div class="pied">
    <div class="w-100 home-footer mt-4">
      <div class="row px-2 pt-2">
        <div class="col-lg-2 col-md-1" />
        <div class="col-lg-4 col-md-5">
          <div class="">
            <AppLogo :blacknwhite="true" :height="100" />
          </div>
        </div>
        <div class="col-lg-3 col-md-3" />
        <div class="col-lg-3 col-md-3">
          <div class="mt-1">
            <div class="grid-gap-10">
              <div>
                <router-link to="/"> A propos </router-link>
              </div>
              <div>
                <router-link to="/contacts"> Nous contacter </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-2 my-1 mt-2">
        <p class="d-flex justify-content-center">
          <span>
            COPYRIGHT © {{ new Date().getFullYear() }}
            <b-link class="ml-25" href="/" target="_blank">{{
              appName
            }}</b-link>
          </span>
        </p>
      </div>
      <ColorBandGouvBj />
    </div>
  </div> -->

  <footer style="background-color: #fff">
    <div class="footer-wrapper">
      <div class="logo">
        <b-img
          class="w-100"
          :src="require('@/assets/images/logo/logo_mtca.png')"
          alt="logo"
        />
      </div>
      <div class="footer-links">
        <ul class="link-block">
          <span>Menu</span>
          <li>
            <router-link to="/">{{ $t('home.home') }}</router-link>
          </li>
          <!-- <li><router-link to="/blog">Blog</router-link></li> -->
          <li>
            <router-link to="/apropos">{{ $t('home.aboutus') }}</router-link>
          </li>
          <li>
            <router-link to="/depot-reclamation">{{
              $t('home.file_complain')
            }}</router-link>
          </li>
          <!-- <li>
            <router-link to="/espaceInvestisseur"
              >Espace AGENT MTCA</router-link
            >
          </li> -->
          <li>
            <router-link to="/espaceInvestisseur">{{
              $t('investorPage.title')
            }}</router-link>
          </li>
        </ul>
        <ul class="link-block">
          <span>Support</span>
          <li><a href="mailto:mtca.dsisa@gouv.bj">mtca.dsisa@gouv.bj</a></li>
          <li><router-link to="faq">FAQ</router-link></li>
          <li><router-link to="/contacts">Contact</router-link></li>
        </ul>
        <ul class="link-block">
          <span>{{$t('footer.legal')}}</span>
          <li>
            <router-link to="/mentions-legales">{{$t('footer.mention')}}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <p>
      COPYRIGHT ©
      <span class="text-primary">{{ new Date().getFullYear() }}</span>
      {{ $t('home.platformName') }}
    </p>
    <div class="colors-bar">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </footer>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue';
import AppLogo from '@/components/AppLogo.vue';
import ColorBandGouvBj from '@/components/ColorBandGouvBj.vue';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BImg,
    BLink,
    AppLogo,
    ColorBandGouvBj,
  },
  directives: {},
  props: {},
  computed: {},
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
};
</script>
<style lang="scss" scoped>
// .pied {
//   overflow-x: hidden;
// }
// .home-footer {
//   background: #2a2828;
//   padding-top: 25px;

//   a {
//     color: white;
//     transition: all 0.1s ease-in 0.05s;
//     &:hover {
//       font-weight: bold;
//     }
//   }
//   .inner {
//     .links {
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);
//       gap: 20px;
//       @media (max-width: 767.98px) {
//         grid-template-columns: 1fr;
//       }
//     }
//   }
// }
// .grid-gap-10 {
//   display: grid;
//   gap: 10px;
// }

// -----------------------------------------------------------

/* FOOTER */
footer {
  position: relative;
  padding: 3em 2em 3em 2em;
}
footer * {
  list-style: none;
}
.footer-wrapper {
  margin: 0 auto;
  max-width: 1000px;
}
footer p {
  text-align: center;
  /* display: inline-block; */
  text-align: center;
  margin: 0 auto;
}
footer .logo {
  width: 180px;
}
footer p {
  margin-top: 2em;
}
footer ul {
  padding: 0 !important;
}
.footer-links {
  display: grid;
  flex: 1;
  gap: 10px;
  margin-top: 2em;
}
.link-block span {
  font-size: 18px;
  font-weight: bold;
}
.link-block li {
  margin: 0.5em 0;
}

.colors-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 7px;
  z-index: 50;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.colors-bar div:nth-child(1) {
  background-color: green;
}
.colors-bar div:nth-child(2) {
  background-color: yellow;
}
.colors-bar div:nth-child(3) {
  background-color: red;
}

/* MEDIA QUERIES */
@media (min-width: 450px) {
  .footer-links {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 660px) {
  footer {
    /* border: 1px solid red; */
  }
  .footer-links {
    /* border: 1px solid red; */
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 768px) {
  .footer-wrapper {
    display: flex;
  }
  .footer-links {
    margin: 0;
    grid-template-columns: 1fr 1fr;
    margin-left: 2em;
  }
}
@media (min-width: 902px) {
  .footer-links {
    margin-left: 4em;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
</style>
